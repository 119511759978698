<script setup>
import { defineProps } from 'vue'
import BaseSection from './BaseSection.vue'
import { getSettingValueInPropsComputed } from '../../composables/useSection'

const props = defineProps({
  section: {
    type: Object,
    required: true,
  },
})

const title = getSettingValueInPropsComputed(props, 'title')
const headerType = getSettingValueInPropsComputed(props, 'type')
</script>

<template>
  <base-section
    :section="section"
  >
    <div class="a-title-section">
      <div class="container">
        <component :is="headerType">
          {{ title }}
        </component>
      </div>
    </div>
  </base-section>
</template>

<style scoped lang="scss">
.a-title-section{
  background: #fff;
  padding: 20px;
}
</style>
